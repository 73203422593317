import React, { Component } from 'react'
import { Link } from 'gatsby'
import Video from 'react-player'

// Components Globals
import Header from 'components/header'
import Navigation from 'components/navigation-solutions'
import Footer from 'components/footer/main'
import Demo from 'components/s-demo/main'
import SEO from 'components/seo'

import { BannerSolucao, TituloPrimario, TextParagrafo, SectionInformacoesGerais, NotasFiscais, SectionVideo } from '../../Style/solucoes'

import { Container, TitleBanner } from '../../Style/global'

import IlustracaoOrganizacaoFiscal from 'images/ilustracao-organizacao-fiscal.svg'
import IlustracaoGestorTributario from 'images/ilustracao-gestor-tributario.svg'
import IlustracaoDocumentosFiscais from 'images/ilustracao-documentos-fiscais.svg'
import IlustracaoHomologacao from 'images/ilustracao-homologacao.svg'
import IlustracaoNotasFiscais from 'images/ilustracao-notas-fiscais.svg'

// Sass Global
import 'sass/main.scss'

class PageOrganizacaoFiscal extends Component {
  render() {
    return (
      <div className="main">
        <SEO title="Organização Fiscal" />

        <Header />

        <Navigation from={this.props.location.pathname} />

        <BannerSolucao className="banner-gestao-performance">
          <Container className="flex">
            <div className="texto">
              <TitleBanner>Organização fiscal</TitleBanner>
              <h1>
                Pague somente
                <br /> o necessário
              </h1>
              <p>
                Tenha segurança na apuração dos impostos e proteja sua
                <br /> farmácia de penalidades fiscais e da bitributação com as
                <br /> ferramentas do INOVAFARMA.
              </p>
              <Link to="/sobre/contato">Solicite uma demonstração</Link>
            </div>
            <img src={IlustracaoOrganizacaoFiscal} alt="Tela sistema Inovafarma com listagem" className="ilustra" />
          </Container>
        </BannerSolucao>

        <SectionInformacoesGerais>
          <Container>
            <div className="item-texto">
              <img src={IlustracaoGestorTributario} alt="Inovafarma gestor tributário" className="ilustra" />
              <div className="texto">
                <TituloPrimario>Gestor tributário</TituloPrimario>
                <p>
                  Um dos fatores determinantes para o pagamento de impostos indevidos é a classificação incorreta das informações fiscais no
                  cadastro dos produtos.
                </p>
                <p>
                  Com o Gestor Tributário INOVAFARMA a farmácia garante a manutenção em todas as alíquotas dos produtos de forma rápida,
                  integrada e assertiva.
                </p>
                <Link to="/sobre/contato">Fale com um especialista</Link>
              </div>
            </div>
            <div className="item-texto">
              <img src={IlustracaoDocumentosFiscais} alt="Inovafarma documentos fiscais" className="ilustra" />
              <div className="texto">
                <TituloPrimario>Documentos fiscais</TituloPrimario>
                <p>Esqueça as dores de cabeça na hora de cumprir as obrigações fiscais com a contabilidade.</p>
                <p>
                  O INOVAFARMA oferece a emissão dos documentos fiscais obrigatórios da farmácia, como Cupom Fiscal, Nota Fiscal, SPED e
                  SINTEGRA para apuração dos impostos.
                </p>
                <Link to="/solucoes/">Saiba como</Link>
              </div>
            </div>
            <div className="item-texto">
              <img src={IlustracaoHomologacao} alt="Inovafarma homologado em todo brasil" className="ilustra" />
              <div className="texto">
                <TituloPrimario>
                  INOVAFARMA é homologado
                  <br /> em todos os estados do Brasil
                </TituloPrimario>
                <p>
                  Acompanhamos todas as regras fiscais e tributárias do território nacional garantindo que sua farmácia esteja adequada às
                  legislações vigentes.
                </p>
                <p>Não perca mais tempo, nossos especialistas estão a sua disposição para ajudar melhorar os resultados da sua farmácia.</p>
                <Link to="/sobre/contato">Fale com um especialista</Link>
              </div>
            </div>
          </Container>
        </SectionInformacoesGerais>

        <NotasFiscais>
          <Container>
            <TituloPrimario>
              Acompanhe as notas fiscais emitidas <br />
              para o CNPJ da farmácia
            </TituloPrimario>
            <TextParagrafo>
              O INOVAFARMA oferece a ferramenta Manifestação de Destinatário para que você possa controlar as notas
              <br /> fiscais emitidas para o CNPJ da sua farmácia podendo confirmar ou desconhecer a operação.
            </TextParagrafo>
            <img src={IlustracaoNotasFiscais} alt="Tela sistema Inovafarma listagem notas emitidas CNPJ da farmácia" className="ilustra" />
          </Container>
        </NotasFiscais>

        <SectionVideo className="bg-gray">
          <Container>
            <TituloPrimario>Veja como é simples fazer a gestão de compra e estoque da sua farmácia</TituloPrimario>
            <Video className="video video-organizacao-fiscal" url="https://www.youtube.com/watch?v=Q5Arr2F75Io" light playing />
          </Container>
        </SectionVideo>

        <Demo />

        <Footer padding_maior={true} />
      </div>
    )
  }
}

export default PageOrganizacaoFiscal
